<div
  *ngIf="!config['isHidden']"
  id="{{config.name}}"
  class="dynamic-field form-input form-element"
  [formGroup]="group"
  [ngClass]="fieldShow"
  [class.has-pwtoggle]="config.togglePw"
  [class.has-tooltip]="config.tooltip"
>

  <div class="label-container">
    <label class="label">{{config.placeholder | translate}} <span *ngIf="config.required">*</span></label>
    <tooltip *ngIf="config.tooltip" [header]="config.placeholder" [message]="config.tooltip"></tooltip>
  </div>

  <mat-form-field class="full-width">
    <mat-chip-list
      #chipList class="form-chip"
      [required]="config.required"
      [formControl]="group.controls[config.name] | cast"
      ix-auto
      ix-auto-type="input"
      ix-auto-identifier="{{config.placeholder}}"
    >
      <mat-chip
        *ngFor="let item of chipLists"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(item)"
      >
        {{item}}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input
        *ngIf="!config.autocomplete; else autoCompleteBlock"
        [placeholder]="config.placeholder | translate"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event)"
        (paste)="paste($event)"
      >
      <ng-template #autoCompleteBlock>
        <input
          [placeholder]="config.placeholder | translate"
          #chipInput
          [formControl]="chipCtrl"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)"
          (keyup)="updateSearchOptions(chipInput.value)"
          [matAutocomplete]="autoComplete"
          (focus)="openPanel()"
        >
        <mat-autocomplete #autoComplete="matAutocomplete" (optionSelected)="selected($event)">
          <mat-option *ngFor="let option of config.searchOptions" [value]="option.value"
              ix-auto ix-auto-type="option" ix-auto-identifier="{{option.label}}">
            <span>{{ option.label | translate }}</span>
          </mat-option>
        </mat-autocomplete>
      </ng-template>
    </mat-chip-list>
  </mat-form-field>

  <div class="margin-for-error">
    <form-errors [control]="group.controls[config.name]" [config]="config"></form-errors>
    <mat-error *ngIf="config['hasErrors']">
      <div [innerHTML]="config['errors']"></div>
    </mat-error>
    <mat-error *ngIf="config.warnings">
      <div [innerHTML]="config.warnings | translate"></div>
    </mat-error>
  </div>
</div>
