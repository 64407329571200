<div class="full-width container has-tooltip">

  <div *ngIf="label" class="label-container">
    <label class="label">
      {{label}}
      <span *ngIf="required" class="required">*</span>
    </label>
    <tooltip *ngIf="tooltip" [header]="label" class="tooltip" [message]="tooltip"></tooltip>
  </div>

  <div class="input-container" [class.disabled]="isDisabled">
    <mat-select
      [(ngModel)]="value"
      [required]="required"
      [disabled]="isDisabled"
      [multiple]="multiple"
      (blur)="onTouch()"
      (ngModelChange)="onChange($event)"
    >
      <mat-option *ngFor="let option of options | async" [value]="option.value">{{option.label}}</mat-option>
    </mat-select>
  </div>
  <ix-errors [control]="controlDirective.control" [label]="label"></ix-errors>

  <mat-hint *ngIf="hint">{{hint}}</mat-hint>

</div>
