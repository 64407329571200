<div class="toolbar-input form-element" id="row-filter">
  <div class="toolbar-input-placeholder" *ngIf="isShowPlaceholder()">{{ config.placeholder | translate }}</div>
  <mat-form-field>
    <span matPrefix class="search-icon-wrapper"><mat-icon>search</mat-icon></span>
    <mat-label>{{ config.placeholder | translate }}</mat-label>
    <input
      matInput
      #filter
      class="mat-input-element"
      [value]="config.value"
      (focus)="onFocus()"
      ix-auto ix-auto-type="input"
      (change)="onChange()"
      (input)="onChange()"
      [placeholder]="config.placeholder | translate"
      (blur)="onBlur()"
    >
    <span
      [ngClass]="{'invisible': !filterValue || filterValue.length == 0}"
      matSuffix
      class="reset-icon-wrapper"
    >
      <mat-icon (click)="reset()" role="img" fontSet="mdi-set" fontIcon="mdi-close-circle"></mat-icon>
    </span>
  </mat-form-field>
</div>
