<div id="{{config.name}}" class="form-task" [formGroup]="group" [ngClass]="fieldShow" *ngIf="!config['isHidden']"
  ix-auto ix-auto-type="task" ix-auto-identifier="{{config.placeholder}}">
  <label class="form-task-label">{{ config.placeholder | translate }}</label>
  <br>
  <mat-card>
    <mat-tab-group #tabGroup (selectChange)="onSelectChange()">
      <mat-tab *ngFor="let field of config.tabs;" label="{{field.tabName | translate}}"
        ix-auto ix-auto-type="tab" ix-auto-identifier="{{field.tabName}}">
        <ng-container dynamicField [config]="field" [group]="tabFormGroup" [fieldShow]="'show'">
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
</div>
