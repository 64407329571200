<h1 mat-dialog-title>{{title | translate}}</h1>
<mat-dialog-content class="resilver-progress-dialog"
	ix-auto ix-auto-type="dialog" ix-auto-identifier="title">
  <div *ngIf="state !== PoolScanState.Finished">
    <mat-progress-bar class="example-margin"
			color="primary"
			mode="indeterminate"
			[value]="progressTotalPercent"
		></mat-progress-bar>
    <label>
      {{ progressTotalPercent | number: '1.2-2'}}%
    </label>
  </div>
  <div>{{ description | translate }}{{ diskName }}</div>
  <div>{{ statusLabel | translate}}{{ state }}</div>
</mat-dialog-content>
<div>
  <button mat-button mat-dialog-close="close"
		ix-auto ix-auto-type="button" ix-auto-identifier="CLOSE">{{"Close" | translate}}</button>
</div>
