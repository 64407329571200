<div class="toolbar-menu">
  <div>

    <!-- TRIGGER -->
    <button mat-button ix-auto ix-auto-type="button" [ix-auto-identifier]="id + '_' + config.label" [matMenuTriggerFor]="menu" [color]="config.color ? config.color : 'default'" class="menu-toggle">
      {{ config.label | translate }} <mat-icon class="menu-caret">arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu" overlapTrigger="false">

      <!-- OPTIONS-->
      <div >
        <button mat-menu-item *ngFor="let option of config.options"
        [disabled]="option.disabled"
        ix-auto ix-auto-type="option"
        [ix-auto-identifier]="id + '_' + option.label"
        (click)="onClick(option);">
          <mat-icon *ngIf="option.icon">{{option.icon}}</mat-icon>
          <span>{{ option.label | translate }}</span>
        </button>
      </div>
    </mat-menu>

  </div>
</div>

