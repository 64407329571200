<div
  *ngIf="!config['isHidden']"
  id="config.name"
  class="dynamic-field"
  [formGroup]="group"
  [ngClass]="fieldShow"
  [class.has-tooltip]="config.tooltip"
>

  <div class="top">
    <label>{{ config.placeholder | translate }}</label>
    <tooltip
      *ngIf="config.tooltip"
      [header]="config.placeholder"
      [message]="config.tooltip"
      [style.margin-left.px]="5"
    ></tooltip>
  </div>

  <mat-selection-list
    [formControlName]="config.name"
    id="{{config.name}}_selectionlist"
    [fxLayout]="selectionListLayout"
    ix-auto ix-auto-type="selection-list" ix-auto-identifier="config.name"
    (selectionChange)="onChangeSelectedItems($event)"
    class="form-selection-list"
  >

    <ng-container *ngFor="let option of config.options">

      <mat-list-option
        checkboxPosition="before"
        [value]="option.value"
        [fxFlex.gt-md]="listOptionFlex" fxFlex="100%"
        id="{{config.name}}_{{option.value}}_listoption"
        color="primary"
        class="form-checkbox"
        ix-auto ix-auto-type="list-option" ix-auto-identifier="{{config.name}}_{{option.label}}"
      >
        {{ option.label | translate }}
      </mat-list-option>

      <tooltip class="list-option-tooltip" *ngIf="option.tooltip" [header]="config.placeholder" [message]="option.tooltip"></tooltip>

    </ng-container>

  </mat-selection-list>

  <mat-error *ngIf="config['hasErrors']"><div [innerHTML]="config['errors']"></div></mat-error>
  <mat-error *ngIf="config.warnings"><div [innerHTML]="config.warnings | translate"></div></mat-error>

</div>
