<div mat-dialog-title>{{ 'TrueCommand Cloud Service' | translate}}</div>
<mat-divider></mat-divider>
<div mat-dialog-content class="tc-dialog-content">
  <div id="status">
    <mat-icon *ngIf="tc.status == TrueCommandStatus.Connected" class="status-connected">check_circle</mat-icon>
    <mat-icon *ngIf="tc.status == TrueCommandStatus.Failed" class="status-failed">cancel</mat-icon>
    <mat-icon *ngIf="tc.status == TrueCommandStatus.Disabled" class="status-disabled">pause_circle_filled</mat-icon>
    <span>{{tc.status | translate}}</span>
  </div>
  <div id="error" *ngIf="tc.status_reason">
    {{tc.status_reason | translate}}
  </div>
  <div id="tc-ip" *ngIf="tc.status === TrueCommandStatus.Connected && tc.remote_ip_address">
    <mat-icon svgIcon="truecommand_logo_blue" class="tc-icon"></mat-icon>
    <span>{{tc.remote_ip_address}}</span>
  </div>
</div>
<div mat-dialog-actions>
  <button
    mat-button color="primary"
    class="tc-action-button"
    ix-auto ix-auto-type="button"
    ix-auto-identifier="SETTINGS"
    (click)="data.parent.updateTrueCommand()"
  > {{ 'Settings' | translate}}</button>
  <button
    mat-button color="primary"
    class="tc-action-button"
    ix-auto ix-auto-type="button"
    ix-auto-identifier="TRUECOMMAND"
    [disabled]="!tc.remote_url || tc.status !== 'CONNECTED'"
    (click)="goToTrueCommand()"
  > {{ 'TrueCommand' | translate}}</button>
</div>
