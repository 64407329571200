<mat-dialog-content>
  <div class="jobs-header" fxLayout="row" fxLayoutAlign="space-between baseline" fxLayoutGap="8px">
    <h3>{{ 'Task Manager' | translate }}</h3>

    <span *ngIf="jobs.length">
      {{ '{n} in progress' | translate: { n: numberOfRunningJobs$ | async } }},
      {{ '{n} in waiting' | translate: { n: numberOfWaitingJobs$ | async } }},
      {{ '{n} failed' | translate: { n: numberOfFailedJobs$ | async } }}
    </span>
  </div>

  <mat-spinner [diameter]="40" *ngIf="isLoading; else loaded"></mat-spinner>

  <ng-template #loaded>
    <ng-container *ngIf="jobs.length; then items; else empty"></ng-container>
  </ng-template>

  <ng-template #items>
    <div [@list]="jobs.length" class="jobs-list">
      <app-job-item
        *ngFor="let job of jobs"
        [job]="job"
        (aborted)="onAbort(job)"
        (opened)="openEntityJobDialog(job)"
      ></app-job-item>
    </div>
  </ng-template>

  <ng-template #empty>
    <entity-empty [conf]="emptyConfig"></entity-empty>
  </ng-template>
</mat-dialog-content>
<mat-dialog-actions *ngIf="jobs.length">
  <button
    (click)="goToJobs()"
    mat-button
    ix-auto
    ix-auto-type="button"
    ix-auto-identifier="HISTORY"
  >{{ 'History' | translate }}</button>
</mat-dialog-actions>
