<mat-dialog-content class="dir-service-monitor-dialog">
  <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <h3>{{ 'Directory Services' | translate }}</h3>

    <div class="header-actions">
      <button mat-icon-button id="refresh-icon" (click)="getStatus()"
        ix-auto ix-auto-type="button" ix-auto-identifier="refresh">
        <mat-icon>refresh</mat-icon>
      </button>

      <button
        class="dir-services-monitor-action-button"
        mat-icon-button
        mat-dialog-close
        ix-auto
        ix-auto-type="button"
        ix-auto-identifier="CLOSE"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>
  <div class="spinner-wrapper" *ngIf="isLoading">
    <mat-spinner [diameter]="40" id="dir-service-monitor-spinner"></mat-spinner>
  </div>
  <mat-table *ngIf="!isLoading" #dirServiceTable [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- Icon Column -->
    <ng-container matColumnDef="icon">
      <mat-header-cell *matHeaderCellDef fxFlex="74px"></mat-header-cell>
      <mat-cell *matCellDef="let element" fxFlex="74px">
        <mat-icon *ngIf="element.state == DirectoryServiceState.Healthy" class="state-healthy">check_circle</mat-icon>
        <mat-icon *ngIf="element.state == DirectoryServiceState.Faulted" class="state-faulted">highlight_off</mat-icon>
        <mat-icon *ngIf="element.state == DirectoryServiceState.Leaving" class="state-leaving">arrow_back</mat-icon>
        <mat-icon *ngIf="element.state == DirectoryServiceState.Joining" class="state-joining">arrow_forward</mat-icon>
        <mat-icon *ngIf="element.state == DirectoryServiceState.Disabled" class="state-disabled">remove_circle</mat-icon>
      </mat-cell>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>{{ 'Name' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.name}}</mat-cell>
    </ng-container>
    <!-- State Column -->
    <ng-container matColumnDef="state">
      <mat-header-cell *matHeaderCellDef>{{ 'State' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.state}}</mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row clickable" (click)="goTo(row.id)"
      ix-auto ix-auto-type="action" ix-auto-identifier="{{row.name}}"></mat-row>
  </mat-table>
</mat-dialog-content>
