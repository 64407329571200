<div
  class="toolbar-button" [class.has-tooltip]="config.tooltip">
  <button
    ix-auto
    ix-auto-type="button"
    [ix-auto-identifier]="getIdentifier()"
    (click)="onClick(true)"
    [color]="config.color ? config.color : 'default'"
    mat-button
    [disabled]="config.disabled">
    {{ config.label | translate }}
  </button>
  <tooltip *ngIf="config.tooltip" [header]="config.placeholder" [message]="config.tooltip"></tooltip>
</div>
