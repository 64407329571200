<div mat-dialog-content>
  <div class="title-div" fxLayout="row" fxLayoutAlign="space-between center">
    <strong class="catalog-name" textLimiter threshold="40" content="{{ catalogApp.name }} {{ 'Application Summary' | translate }}"></strong>
    <span class="status active" *ngIf="catalogApp.healthy">{{ 'Healthy' | translate }}</span>
    <span class="status other" *ngIf="!catalogApp.healthy">{{ 'Unhealthy' | translate }}</span>
  </div>
  <div class="description" [innerHTML]="catalogApp.app_readme">
  </div>
  <div class="categories" fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <span>{{ 'Catalog' | translate }}:</span>
      <div class="value capitalized-text" textLimiter threshold="20" [content]="catalogApp.catalog.label"></div>
    </div>
    <div>
      <span>{{ 'Train' | translate }}:</span>
      <div class="value capitalized-text" textLimiter threshold="20" [content]="catalogApp.catalog.train"></div>
    </div>
  </div>
  <div class="categories">
    <span>{{ 'Categories' | translate }}:</span>
    <span class="value" textLimiter threshold="35" content="{{ catalogApp.categories.join(', ') }}"></span>
  </div>
  <div class="resource" fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <span>{{ 'Versions' | translate }}</span>
    </div>
    <div class="status-filter">
      <span>{{ 'Status' | translate }}:</span>
      <mat-form-field class="form-element ">
        <mat-select [(ngModel)]="selectedStatus"  (ngModelChange)="onStatusOptionChanged()"
                  ix-auto ix-auto-type="select" ix-auto-identifier="status-select">
          <mat-option *ngFor="let option of statusOptions" value="{{ option | translate }}">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>
  </div>
  <div class="version-list">
    <div fxLayout="row" fxLayoutAlign="center start" class="detail-row row-light" >
      <span fxFlex="70%">{{'Version' | translate }}</span>
      <span fxFlex="30%" class="text-center" >{{'Version Info' | translate }}</span>
    </div>
    <div *ngIf="hasFilterResult();else noVersions">
      <div *ngFor="let version of filteredVersions | keyvalue; let i = index" class="detail-row" [ngClass]="{'row-dark': i%2==0, 'row-light': i%2==1}">
        <div fxLayout="row" fxLayoutAlign="center start">
          <div fxFlex="70%" class="version-number">
            <span textLimiter threshold="30" content="{{ version.key }}"></span>
            <span>{{ versionStatusLabel(version) }}</span>
          </div>
          <span fxFlex="30%" class="text-center info-col" >
            <mat-icon class="version-tooltip" htmlTooltip="{{ version.value.healthy?version.value.app_readme:version.value.healthy_error }}">info</mat-icon>
          </span>
        </div>
      </div>
    </div>
    <ng-template #noVersions>
      <div fxLayout="row" fxLayoutAlign="start start" class="detail-row row-dark version-number">
        {{ 'No Version' | translate }}
      </div>
    </ng-template>

  </div>
</div>

<div class="button-bar" mat-dialog-actions align="end">
  <button mat-button class="mat-button mat-secondary" (click)="dialogRef.close(false)"
        ix-auto ix-auto-type="button" ix-auto-identifier="CLOSE">
    {{ helptext.actionBtnText.close | translate }}
  </button>
</div>
