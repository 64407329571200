<div id="{{config.name}}" class="dynamic-field mat-radio" [formGroup]="group" [ngClass]="fieldShow" [class.has-tooltip]="config.tooltip" *ngIf="!config['isHidden']">
  <div class="top">
    <label>{{ config.placeholder | translate }}</label>
    <tooltip *ngIf="config.tooltip" [header]="config.placeholder" [message]="config.tooltip" [style.margin-left.px]="5"></tooltip>
  </div>
  <mat-radio-group [formControlName]="config.name" id="{{config.name}}_radiogroup" (change)="onChangeRadio($event)"
    [fxLayout]="radioLayout" ix-auto ix-auto-type="radio-group" ix-auto-identifier="{{config.name}}">
    <div *ngFor="let option of config.options" [fxFlex.gt-md]="radioFlex" fxFlex="100%" fxLayout="row">
      <mat-radio-button *ngIf="!option.hiddenFromDisplay" [checked]="option.value === radioValue" [value]="option.value" id="{{config.name}}_{{option.value}}_radiobutton"
          ix-auto
          ix-auto-type="radio"
          ix-auto-identifier="{{config.name}}_{{option.label}}">
        {{ option.label | translate }} 
      </mat-radio-button>
      <tooltip class="radio-option-tooltip" *ngIf="option.tooltip" [header]="config.placeholder" [message]="option.tooltip"></tooltip>
    </div>
  </mat-radio-group>
  <mat-error *ngIf="config['hasErrors']"><div [innerHTML]="config['errors']"></div></mat-error>
  <mat-error *ngIf="config.warnings"><div [innerHTML]="config.warnings | translate"></div></mat-error>
</div>
