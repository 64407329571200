<mat-sidenav-container class="app-side-nav-container" fxLayout="row" ngClass.xs="xs" ngClass.sm="sm" ngClass.md="md" ngClass.lg="lg" ngClass.xl="xl">
  <!-- Main side navigation -->
  <mat-sidenav fxFlex="sidenavWidth" #sidenav [opened]="isSidenavOpen" [mode]="sidenavMode" (closedStart)="onMenuClosed()" class="sidebar-panel not-alerts">
    <div id="scroll-area" class="navigation-hold" fxLayout="column">
      <div *ngFor="let theme of freenasThemes">
        <div *ngIf="theme.name == currentTheme" ix-auto ix-auto-type="button" ix-auto-identifier="logo">

          <!-- FreeNAS Retro -->
          <div class="branding retro topbar" *ngIf="retroLogo">
            <a routerLink="/dashboard" class="logo"><mat-icon svgIcon="freenas_logomark" alt="FreeNAS logo" class="app-logo"></mat-icon></a>
            <a routerLink="/dashboard" class="logo-text"><mat-icon svgIcon="freenas_logotype" alt="FreeNAS" class="app-logo-text"></mat-icon></a>
          </div>

          <!-- TrueNAS Core -->
          <div class="branding topbar" *ngIf="!retroLogo && productType == ProductType.Core ">
            <a routerLink="/dashboard" class="logo">
              <mat-icon [svgIcon]="themeService.isDefaultTheme ? 'truenas_core_logomark_color' : 'truenas_core_logomark'" alt="TrueNAS Core logo" class="app-logo"></mat-icon>
            </a>
            <a routerLink="/dashboard" class="logo-text">
              <mat-icon [svgIcon]="themeService.isDefaultTheme ? 'truenas_core_logotype_color' : 'truenas_core_logotype'" alt="TrueNAS Core" class="app-logo-text"></mat-icon>
            </a>
          </div>

          <!-- TrueNAS Enterprise -->
          <div class="branding topbar" *ngIf="!retroLogo && productType == ProductType.Enterprise ">
            <a routerLink="/dashboard" class="logo">
              <mat-icon [svgIcon]="themeService.isDefaultTheme ? 'truenas_enterprise_logomark_color' : 'truenas_enterprise_logomark'" alt="TrueNAS Core logo" class="app-logo"></mat-icon>
            </a>
            <a routerLink="/dashboard" class="logo-text">
              <mat-icon [svgIcon]="themeService.isDefaultTheme ? 'truenas_enterprise_logotype_color' : 'truenas_enterprise_logotype'" alt="TrueNAS Core" class="app-logo-text"></mat-icon>
            </a>
          </div>

          <!-- TrueNAS Scale -->
          <div class="branding topbar {{'truenas-' + productType.toLowerCase()}}" *ngIf="!retroLogo && (productType == ProductType.Scale || productType == ProductType.ScaleEnterprise) ">
            <a routerLink="/dashboard" class="logo">
              <mat-icon [svgIcon]="themeService.isDefaultTheme ? 'truenas_scale_logomark_color' : 'truenas_scale_logomark'" alt="TrueNAS Core logo" class="app-logo"></mat-icon>
            </a>
            <a routerLink="/dashboard" class="logo-text">
              <mat-icon [svgIcon]="themeService.isDefaultTheme ? 'truenas_scale_logotype_color' : 'truenas_scale_logotype'" alt="TrueNAS Core" class="app-logo-text"></mat-icon>
            </a>
          </div>
        </div>
      </div>

      <navigation (menuToggled)="toggleMenu($event)" (menuClosed)="toggleMenu()"></navigation>

      <div class="slidein-nav slidein-nav-sm" [class.slidein-open]="isOpen">
        <app-secondary-menu [subMenuItems]="subs" [menuName]="menuName" (toggleMenu)="toggleMenu()"></app-secondary-menu>
      </div>

      <div *ngIf="!isSidenavCollapsed" class="sidenav-copyright-txt">
        <div *ngIf="hostname" class="hostname-label" matTooltip="Hostname: {{hostname}}">{{hostname}}</div>
        <div>TrueNAS {{productType}}®</div>
        <div>
          © {{copyrightYear}} - <a href="http://www.ixsystems.com"
              target="_blank" title="iXsystems, Inc."> iXsystems, Inc</a>.
        </div>
      </div>
      <div
        *ngIf="isSidenavCollapsed"
        class="sidenav-copyright-icon"
        matTooltip="TrueNAS {{productType}}® © {{copyrightYear}} by iXsystems Inc."
        matTooltipPosition="right"
      >
        <mat-icon>copyright</mat-icon>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content fxFlex="calc(100% - {{sidenavWidth}})" class="fn-maincontent" [style.margin-left]="sidenavWidth">
    <!-- Top Bar -->
    <!-- Template reference variables of Left sidebar and Right notification is supplied -->
    <!-- Listens language change event -->
    <topbar [sidenav]="sidenav" [notificPanel]="notificationPanel"></topbar>

    <div class="slidein-nav slidein-nav-md" [class.slidein-open]="isOpen">
      <app-secondary-menu [subMenuItems]="subs" [menuName]="menuName" (toggleMenu)="toggleMenu()"></app-secondary-menu>
    </div>
    <div class="overlay" [class.isdark]="isOpen" (click)="toggleMenu()"></div>

    <!-- App content -->
    <div class="rightside-content-hold" [ngClass]="{'has-footer':isShowFooterConsole}">
      <pagetitle [breadcrumbs]="true" [productType]="productType"></pagetitle>
      <router-outlet></router-outlet>
    </div>
    <div class="footer-console-bar" *ngIf="isShowFooterConsole" ix-auto ix-auto-type="shell" ix-auto-identifier="footer-console">
      <pre #footerBarScroll class="message" (click)="onShowConsolePanel()">{{consoleMsg}}</pre>
    </div>
  </mat-sidenav-content>
  <!-- Notification bar -->
  <mat-sidenav #notificationPanel mode="over" align="end" (open)="onOpenNotify()" (close)="onCloseNotify()" class="notification-sidenav" position="end">
    <div class="navigation-hold" fxLayout="column">
      <app-notifications [notificPanel]="notificationPanel"></app-notifications>
    </div>
  </mat-sidenav>
</mat-sidenav-container>

<jw-modal id="slide-in-form"></jw-modal>
<ix-slide-in id="ix-slide-in-form"></ix-slide-in>
