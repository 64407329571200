<div
  *ngIf="!config['isHidden']"
  id="{{config.name}}"
  class="dynamic-field form-readfile"
  [formGroup]="group"
  [ngClass]="fieldShow"
  [class.has-tooltip]="config.tooltip"
  ix-auto ix-auto-type="readfile" ix-auto-identifier="{{config.placeholder}}"
>
  <input type="file" id="input" (change)="changeListener($event)">
  <tooltip *ngIf="config.tooltip" [header]="config.placeholder" [message]="config.tooltip"></tooltip>
  <mat-error *ngIf="config['hasErrors']">{{config['errors']}}</mat-error>
</div>
