<div 
  id="{{config.name}}"
  class="dynamic-field form-checkbox form-element {{ config.inlineLabel ? 'inline-label' : 'not' }}"
  [formGroup]="group" 
  [ngClass]="fieldShow" 
  [class.has-tooltip]="config.tooltip" 
  *ngIf="!config['isHidden']" 
  [class.expanded-height]="config.expandedHeight"
>

  <ng-container *ngIf="config.inlineLabel">
    <div class="label half-width"></div>
  </ng-container>
  <mat-checkbox
    *ngIf="!config.updater && !config.customEventMethod"
    color="primary"
    [formControlName]="config.name"
    [required]="config.required"
    ix-auto ix-auto-type="checkbox" ix-auto-identifier="{{config.placeholder}}"
    (click)="(config.readonly && preventClick($event))"
    class="{ 'full-width': !config.inlineLabel, 'half-width': config.inlineLabel }"
    (change)="onChangeCheckbox($event)"
  >
    {{ config.placeholder | translate }}
  </mat-checkbox>

  <mat-checkbox

    *ngIf="config.updater"
    color="primary"
    class="updater { 'full-width': !config.inlineLabel, 'half-width': config.inlineLabel }"
    [formControlName]="config.name"
    [required]="config.required"
    (change)="onChangeCheckbox($event)"
    (click)="((config.readonly && preventClick($event)) || checkboxUpdate())"
  >
    {{ config.placeholder | translate }}
  </mat-checkbox>

  <mat-checkbox
    *ngIf="config.customEventMethod"
    color="primary"
    class="custom-handler { 'full-width': !config.inlineLabel, 'half-width': config.inlineLabel }"
    [formControlName]="config.name"
    [required]="config.required"
    (change)="onChangeCheckbox($event)"
    (click)="( preventClick($event) && config.customEventMethod())"
  >
    {{ config.placeholder | translate }}
  </mat-checkbox>

  <span *ngIf="config.required">*</span>
  <tooltip
    *ngIf="config.tooltip"
    [header]="config.placeholder"
    [message]="config.tooltip"
  ></tooltip>
  <mat-error *ngIf="config['hasErrors']"><div [innerHTML]="config['errors']"></div></mat-error>
  <mat-error *ngIf="config.warnings"><div [innerHTML]="config.warnings | translate"></div></mat-error>
</div>
