<mat-list role="list">
  <ng-container *ngFor="let subItem of subMenuItems">
    <mat-list-item
      *ngIf="!subItem.disabled"
      routerLinkActive="selected"
      class="sidebar-list-item slide-in-nav-item"
      role="listitem"
      ix-auto ix-auto-type="option" [ix-auto-identifier]="subItem.name"
      (click)="toggleMenu.emit()"
    >
      <a *ngIf="!subItem.disabled" [routerLink]="['/', menuName, subItem.state]" class="slidein-nav-link">
        {{subItem.name | translate}}
      </a>
    </mat-list-item>
  </ng-container>
</mat-list>
