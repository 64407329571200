<div
  *ngIf="!config['isHidden']"
  [id]="config.name"
  class="dynamic-field form-input colorpicker"
  [formGroup]="group"
  [ngClass]="fieldShow"
  [class.has-tooltip]="config.tooltip"
  ix-auto ix-auto-type="colorpicker" ix-auto-identifier="{{config.placeholder}}"
>
  <div (click)="togglePicker()" class="color-swatch" [style.background-color]="colorProxy"></div>
  <mat-form-field class="full-width">
    <input
      #input
      matInput
      [placeholder]="config.placeholder | translate"
      [value]="colorProxy"
      (input)="colorProxy=input.value"
    >

    <input
      matInput
      (cpInputChange)="inputListener('cpInputChange', $event)"
      (colorPickerChange)="cpListener('cpInputChange' , $event)"
      [(colorPicker)]="colorProxy"
      [cpToggle]="picker"
      cpOutputFormat="hex"
      cpPosition="bottom"
      cpWidth="180px"
      [cpPositionRelativeToArrow]="true"
      [formControlName]="config.name"
      class="picker-input"
    >
  </mat-form-field>
  <tooltip
    *ngIf="config.tooltip"
    [header]="config.placeholder"
    [message]="config.tooltip | translate"
  ></tooltip>
  <mat-error *ngIf="config['hasErrors']">{{config['errors']}}</mat-error>
</div>
