<h1 mat-dialog-title class="entity-dialog-form-title" [innerHTML]="title | translate"></h1>

<div mat-dialog-content ix-auto ix-auto-type="dialog" ix-auto-identifier="title" class="entity-dialog-form-content">
  <div class="entity-dialog-form-message">
    <span [innerHTML]="conf.message | translate"></span>
  </div>
  <div *ngIf="conf.confirmInstructions" >
    <div [innerHTML]="instructions" [ngStyle]="{marginTop:'10px', marginBottom: '-1rem'}"></div>
    <div id="double-confirm-mask" [ngStyle]="{opacity: '.38', position: 'relative', top: '35px'}"><pre>{{conf['name']}}</pre></div>
  </div>
  <ng-container *ngFor="let field of fieldConfig;" dynamicField [config]="field" [group]="formGroup" fieldShow="show">
    <button
      *ngIf="isButtonVisible(field)"
      mat-icon-button
      type="button"
      (click)="togglePassword()"
      ix-auto
      ix-auto-type="button"
      ix-auto-identifier="{{field.placeholder}}_toggle-pw"
    >
      <mat-icon matTooltip="Hide" *ngIf="showPassword">visibility</mat-icon>
      <mat-icon matTooltip="Show" *ngIf="!showPassword">visibility_off</mat-icon>
    </button>
  </ng-container>
  <span [innerHTML]="conf.warning | translate"></span>
</div>
<div>
  <mat-error *ngIf="error" type="danger" id="error_message"><div [innerHTML]="error | translate"></div></mat-error>
</div>

<div mat-dialog-actions>
  <mat-checkbox
    color="primary"
    *ngIf="confirmCheckbox"
    id="confirm-dialog__confirm-checkbox"
    name="confirm_checkbox"
    class="confirm-checkbox"
    (change)="toggleSubmit($event)"
    ix-auto
    ix-auto-type="checkbox"
    ix-auto-identifier="CONFIRM"
  >{{"Confirm" | translate}}</mat-checkbox>
  <span fxFlex></span>
  <button mat-button *ngIf="!conf.hideCancel" class="mat-button mat-accent action-btn" (click)="cancel()" [name]="cancelButtonText + '_button'">{{cancelButtonText | translate}}</button>
  <span *ngFor="let custBtn of conf.custActions">
    <button
      *ngIf="!conf.isCustActionVisible || conf.isCustActionVisible(custBtn.id)"
      type="button"
      id="cust_button_{{custBtn.id}}"
      mat-button class="mat-button action-btn"
      [ix-auto]=""
      ix-auto-type="button"
      ix-auto-identifier="{{custBtn.name | uppercase}}"
      (click)="custBtn['function']()" [name]="custBtn.name + '_button'"
      [ix-auto]=""
      ix-auto-type="button"
      ix-auto-identifier="{{cancelButtonText | uppercase}}"
    >{{custBtn.name | translate}}</button>
  </span>
  <button mat-button class="mat-button mat-primary" (click)="submit()" [disabled]="!formGroup.valid || !submitEnabled"
    [ix-auto]=""
    ix-auto-type="button"
    ix-auto-identifier="{{saveButtonText | uppercase}}"
    *ngIf="saveButtonText" [name]="saveButtonText + '_button'">{{saveButtonText | translate}}</button>
</div>
