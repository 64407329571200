<ix-modal-header [title]="'Pull Image' | translate" [loading]="isFormLoading"></ix-modal-header>
<mat-card>
  <mat-card-content>
    <form [formGroup]="form" class="ix-form-container" (submit)="onSubmit()">
      <ix-fieldset>
        <ix-input
          formControlName="from_image"
          [label]="'Image Name' | translate"
          [required]="true"
          [tooltip]="tooltips.from_image | translate"
        ></ix-input>
        <ix-input
          formControlName="tag"
          [label]="'Image Tag' | translate"
          [tooltip]="tooltips.tag | translate"
        ></ix-input>
      </ix-fieldset>
      <ix-fieldset [title]="'Docker Registry Authentication' | translate">
        <p class="hint">{{ 'Optional. Only needed for private images.' | translate }}</p>
        <ix-input
          formControlName="username"
          [label]="'Username' | translate"
          [tooltip]="tooltips.username | translate"
        ></ix-input>
        <ix-input
          formControlName="password"
          [label]="'Password' | translate"
          type="password"
          [tooltip]="tooltips.password | translate"
        ></ix-input>
      </ix-fieldset>

      <div class="form-actions">
        <button
          mat-button
          type="submit"
          [disabled]="form.invalid || isFormLoading"
          color="primary"
        >{{ 'Save' | translate }}</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
