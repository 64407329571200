<div id="{{config.name}}" class="dynamic-field form-textarea" [formGroup]="group" [ngClass]="fieldShow" [class.has-tooltip]="config.tooltip" *ngIf="!config['isHidden']">
  <mat-form-field class="full-width">
    <input type="text" #textAreaSsh matInput [placeholder]="config.placeholder | translate" [formControlName]="config.name" [ngClass]="config.class" [required]="config.required" />
  </mat-form-field>
  <mat-card-actions>
    <button
      mat-button
      color="primary"
      (click)="customEventMethod($event)"
      ix-auto ix-auto-type="button" ix-auto-identifier="{{config.customEventActionLabel | uppercase}}"
    >
      {{config.customEventActionLabel}}
    </button>
  </mat-card-actions>
  <tooltip *ngIf="config.tooltip" [header]="config.placeholder" [message]="config.tooltip"></tooltip>
  <mat-error *ngIf="config['hasErrors']"><div [innerHTML]="config['errors']"></div></mat-error>
  <mat-error *ngIf="config.warnings"><div [innerHTML]="config.warnings | translate"></div></mat-error>
</div>
