<div mat-dialog-content id="console-box">
  <pre #footerBarScroll class="message">{{consoleMsg}}</pre>
  <div mat-dialog-content class="overflow-hide">
    <mat-checkbox color="primary" (change)="onStopRefresh($event)"
      ix-auto ix-auto-type="mat-checkbox" ix-auto-identifier="{{refreshMsg}}">{{refreshMsg}}</mat-checkbox>
  </div>
</div>
<div mat-dialog-actions>
  <span fxFlex></span>
  <button mat-button class="btn btn-accent mat-accent" (click)="dialogRef.close(false)"
    ix-auto ix-auto-type="button" ix-auto-identifier="CLOSE">{{"Close" | translate}}</button>
</div>
<div id="placeholder"></div>
