<h1 mat-dialog-title>
  <mat-icon *ngIf="conf.icon" class="general-dialog-icon">{{conf.icon}}</mat-icon>
  <span *ngIf="conf.title">{{conf.title}}</span>
</h1>
<div mat-dialog-content class="general-dialog-content-container">
  <div class="generial-dialog-content">
    <span *ngIf="conf.is_html; else normalContent" [innerHTML]="conf.message | translate"></span>
    <ng-template #normalContent>
      <span>{{conf.message}}</span>
    </ng-template>
  </div>
</div>
<div mat-dialog-actions>
  <mat-checkbox color="primary" *ngIf="conf.confirmCheckbox" (change)="confirmed = !confirmed">{{conf.confirmCheckboxMsg || 'CONFIRM'}}</mat-checkbox>
  <span fxFlex></span>
  <button *ngIf="!conf.hideCancel" mat-button  class="mat-button mat-accent" (click)="dialogRef.close(false)">{{conf.cancelBtnMsg || 'CANCEL'}}</button>
  <button mat-button  class="mat-button mat-primary" [disabled]="isDisabled()" (click)="dialogRef.close(true)">{{conf.confirmBtnMsg || 'CLOSE'}}</button>
</div>
