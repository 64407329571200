<ng-container *ngIf="popupIsVisible && showPopup">
  <div class="copy-btn-popup tooltip-container">
    <div class="text-limiter-tooltip">

      <div class="copy-btn-header">
        <div class="copy-btn-header-txt">
          (Copied to clipboard)
        </div>

        <div class="copy-btn-close-trigger">
          <button mat-icon-button (click)="onPopupClose()">
            <mat-icon class="copy-btn-icon" role="img" fontSet="mdi-set" fontIcon="mdi-close"></mat-icon>
          </button>
        </div>

      </div>

      <div class="copy-btn-copied-txt">{{ text }}</div>


    </div>
  </div>
</ng-container>

<button class="copy-btn-trigger" mat-icon-button (click)="onIconClick()">
  <mat-icon class="copy-btn-icon" role="img" fontSet="mdi-set" fontIcon="mdi-clipboard-text"></mat-icon>
  <input #el class="invisible" [value]="text" type="text">
</button>

