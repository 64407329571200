<div id="{{config.name}}" class="dynamic-field form-select form-element" [formGroup]="group" [ngClass]="fieldShow" [class.has-tooltip]="config.tooltip" *ngIf="!config['isHidden']"
  ix-auto ix-auto-type="scheduler" ix-auto-identifier="{{config.placeholder}}">
    <div class="label-container">
      <mat-label class="label input-text">{{config.placeholder}} <span *ngIf="config.required">*</span></mat-label>
      <tooltip *ngIf="config.tooltip" [header]="config.placeholder" [message]="config.tooltip"></tooltip>
    </div>
    <mat-form-field class="full-width" matTooltipPosition="above" [ngClass]="{'overlay-opened': isOpen}">
      <mat-select
        [(value)]="selectedOption"
        [formControlName]="config.name"
        [required]="config.required"
        [placeholder]="config.placeholder | translate"
        (change)="onChangeOption($event)"
        >
        <mat-option *ngFor="let preset of presets" [value]="preset.value">
          {{ preset.label | translate }} <em class="crontab">({{preset.value}})</em>
          <span class="text-small preset-description" *ngIf="preset.description"> {{preset.description}}</span>
        </mat-option>
        <mat-option #trigger="cdkOverlayOrigin" cdkOverlayOrigin (click)="customClicked()" [value]="customOption.value">
          {{ 'Custom' | translate }} <em class="crontab">({{customOption.value}})</em>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="margin-for-error">
      <form-errors [control]="group.controls[config.name]" [config]="config"></form-errors>
      <mat-error *ngIf="config['hasErrors']">{{config['errors']}}</mat-error>
    </div>

    <ng-template (backdropClick)="backdropClicked()"
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isOpen">
      <mat-card  class="mat-card advanced-date-picker" role="dialog">
        <button class="close-btn" mat-icon-button (click)="togglePopup()"><mat-icon>cancel</mat-icon></button>
        <div class="mat-calendar-content">
          <h4 class="preview-label">{{"Schedule Preview" | translate}}
            <div class="controls">
              <button mat-icon-button [disabled]="disablePrevious" (click)="setCalendar('previous')">
                <mat-icon aria-label="Previous Month">keyboard_arrow_left</mat-icon>
              </button>
              <button mat-icon-button (click)="setCalendar('next')">
                <mat-icon aria-label="Next Month">keyboard_arrow_right</mat-icon>
              </button>
            </div>
          </h4>
          <mat-divider></mat-divider>
          <div class="calendar-wrapper">
            <mat-month-view #calendar
              [activeDate]="activeDate">
            </mat-month-view>
          </div>
          <mat-divider id="spacer"></mat-divider>
          <ul class="schedule-list" #preview>
            <div class="text-small time-zone-label"><strong>{{"System Time Zone:" | translate}}</strong> {{timezone}}</div>
            <ng-container *ngFor="let date of generatedSchedule;">
              <li>{{ date | date:ngDateFormat:convertToUtcOffset(date._date._offset) }}</li>
            </ng-container>
          </ul>
        </div>

        <div class="cron-fields">

          <section class="text-left">
            <h4 class="preview-label">{{"Presets" | translate}}</h4>
            <div class="form-element">
              <mat-form-field class="cron-presets">
                <mat-select [placeholder]="'Choose a preset' | translate" [(ngModel)]="preset" [ngModelOptions]="{standalone: true}">
                  <mat-option *ngFor="let preset of presets" [value]="preset">
                    {{ preset.label | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </section>
          <mat-divider></mat-divider>

          <section class="m-h-d text-left">
            <div class="preview-label">
              <h4 class="preview-label"><span *ngIf="!config.noMinutes">
                  Minutes/</span>Hours/Days
                <tooltip [header]="config.noMinutes ? helptext.scheduler.general.headerWithoutMinutes : helptext.scheduler.general.header" [message]="helptext.scheduler.general.tooltip | translate"></tooltip>
              </h4>
            </div>
            <div id="no-spaces">({{"Separate values with commas, and without spaces." | translate}})</div>

            <div class="form-element" *ngIf="!config.noMinutes">
              <div class="label-container">
                <mat-label class="label input-text">Minutes</mat-label>
                <tooltip [header]="helptext.scheduler.minutes.header" [message]="helptext.scheduler.minutes.tooltip"></tooltip>
              </div>
              <mat-form-field>
                <input matInput [(ngModel)]="minutes" [ngModelOptions]="{standalone: true}" [value]="minutes" [placeholder]="'Minutes' | translate" required [ngClass]=" {'invalid' : validMinutes === false}">
              </mat-form-field>
            </div>

            <div class="form-element">
              <div class="label-container">
                <mat-label class="label input-text">Hours</mat-label>
                <tooltip [header]="helptext.scheduler.hours.header" [message]="helptext.scheduler.hours.tooltip"></tooltip>
              </div>
              <mat-form-field>
                <input matInput [(ngModel)]="hours" [ngModelOptions]="{standalone: true}" [value]="hours" [placeholder]="'Hours' | translate" required [ngClass]="{'invalid': validHours === false}">
              </mat-form-field>
            </div>

            <div class="form-element">
              <div class="label-container">
                <mat-label class="label input-text">Days</mat-label>
                <tooltip [header]="helptext.scheduler.days.header" [message]="helptext.scheduler.days.tooltip | translate"></tooltip>
              </div>
              <mat-form-field>
                <input matInput [(ngModel)]="days" [ngModelOptions]="{standalone: true}" [value]="days" [placeholder]="'Days' | translate" required [ngClass]="{'invalid': validDays === false}">
              </mat-form-field>
            </div>
          </section>
          <mat-divider></mat-divider>

          <!-- Months-->
          <section>
            <h4 class="preview-label">{{"Months" | translate}}</h4>
            <table class="months jan-jun">
              <tbody>
                <tr>
                  <!--<td>All</td>-->
                  <td>{{"Jan" | translate}}</td>
                  <td>{{"Feb" | translate}}</td>
                  <td>{{"Mar" | translate}}</td>
                  <td>{{"Apr" | translate}}</td>
                  <td>{{"May" | translate}}</td>
                  <td>{{"Jun" | translate}}</td>
                </tr>
                <tr>
                  <!--<td><mat-checkbox class="month"></mat-checkbox></td>-->
                  <td><mat-checkbox color="primary" [(ngModel)]="jan" [ngModelOptions]="{standalone: true}" class="month"></mat-checkbox></td>
                  <td><mat-checkbox color="primary" [(ngModel)]="feb" [ngModelOptions]="{standalone: true}" class="month"></mat-checkbox></td>
                  <td><mat-checkbox color="primary" [(ngModel)]="mar" [ngModelOptions]="{standalone: true}" class="month"></mat-checkbox></td>
                  <td><mat-checkbox color="primary" [(ngModel)]="apr" [ngModelOptions]="{standalone: true}" class="month"></mat-checkbox></td>
                  <td><mat-checkbox color="primary" [(ngModel)]="may" [ngModelOptions]="{standalone: true}" class="month"></mat-checkbox></td>
                  <td><mat-checkbox color="primary" [(ngModel)]="jun" [ngModelOptions]="{standalone: true}" class="month"></mat-checkbox></td>
                </tr>
              </tbody>
            </table>
            <table class="months jul-dec">
              <tbody>
                <tr>
                  <!--<td>All</td>-->
                  <td>{{"Jul" | translate}}</td>
                  <td>{{"Aug" | translate}}</td>
                  <td>{{"Sep" | translate}}</td>
                  <td>{{"Oct" | translate}}</td>
                  <td>{{"Nov" | translate}}</td>
                  <td>{{"Dec" | translate}}</td>
                </tr>
                <tr>
                  <!--<td><mat-checkbox class="month"></mat-checkbox></td>-->
                  <td><mat-checkbox color="primary" [(ngModel)]="jul" [ngModelOptions]="{standalone: true}" class="month"></mat-checkbox></td>
                  <td><mat-checkbox color="primary" [(ngModel)]="aug" [ngModelOptions]="{standalone: true}" class="month"></mat-checkbox></td>
                  <td><mat-checkbox color="primary" [(ngModel)]="sep" [ngModelOptions]="{standalone: true}" class="month"></mat-checkbox></td>
                  <td><mat-checkbox color="primary" [(ngModel)]="oct" [ngModelOptions]="{standalone: true}" class="month"></mat-checkbox></td>
                  <td><mat-checkbox color="primary" [(ngModel)]="nov" [ngModelOptions]="{standalone: true}" class="month"></mat-checkbox></td>
                  <td><mat-checkbox color="primary" [(ngModel)]="dec" [ngModelOptions]="{standalone: true}" class="month"></mat-checkbox></td>
                </tr>
              </tbody>
            </table>
          </section>

          <mat-divider></mat-divider>

          <!-- Days of Week-->
          <section>
            <h4 class="preview-label">{{"Days of Week" | translate}}</h4>
            <table class="days-of-week">
              <tbody>
                <tr>
                  <!--<td>All</td>-->
                  <td>{{"Sun" | translate}}</td>
                  <td>{{"Mon" | translate}}</td>
                  <td>{{"Tue" | translate}}</td>
                  <td>{{"Wed" | translate}}</td>
                  <td>{{"Thu" | translate}}</td>
                  <td>{{"Fri" | translate}}</td>
                  <td>{{"Sat" | translate}}</td>
                </tr>
                <tr>
                  <td><mat-checkbox color="primary" [(ngModel)]="sun" [ngModelOptions]="{standalone: true}" class="weekday"></mat-checkbox></td>
                  <td><mat-checkbox color="primary" [(ngModel)]="mon" [ngModelOptions]="{standalone: true}" class="weekday"></mat-checkbox></td>
                  <td><mat-checkbox color="primary" [(ngModel)]="tue" [ngModelOptions]="{standalone: true}" class="weekday"></mat-checkbox></td>
                  <td><mat-checkbox color="primary" [(ngModel)]="wed" [ngModelOptions]="{standalone: true}" class="weekday"></mat-checkbox></td>
                  <td><mat-checkbox color="primary" [(ngModel)]="thu" [ngModelOptions]="{standalone: true}" class="weekday"></mat-checkbox></td>
                  <td><mat-checkbox color="primary" [(ngModel)]="fri" [ngModelOptions]="{standalone: true}" class="weekday"></mat-checkbox></td>
                  <td><mat-checkbox color="primary" [(ngModel)]="sat" [ngModelOptions]="{standalone: true}" class="weekday"></mat-checkbox></td>
                </tr>
              </tbody>
            </table>
          </section>

          <mat-divider></mat-divider>

          <button class="save-button" mat-button color="primary" (click)="onPopupSave()" [disabled]="validPopup()">{{"Done" | translate}}</button>
        </div>
      </mat-card>
    </ng-template>
</div>


<!--</mat-form-field>-->
