<div
  *ngIf="!config['isHidden']"
  id="{{config.name}}"
  class="dynamic-field form-explorer form-element"
  [formGroup]="group"
  [ngClass]="fieldShow"
  [class.has-tooltip]="config.tooltip"
  ix-auto ix-auto-type="explorer" ix-auto-identifier='{{config.placeholder}}'
>

  <div class="label-container">
    <label>
      {{config.placeholder | translate}}
      <span *ngIf="config.required">*</span>
    </label>
    <tooltip class="formexinput-tooltip" *ngIf="config.tooltip" [header]="config.placeholder" [message]="config.tooltip"></tooltip>
  </div>

  <div class="tbody">
    <div class="form-ex-flex-container tr" id="form-ex-flex-container">
      <div id="box2" class="td">
        <mat-form-field class="full-width" id="formexinput">
          <textarea matInput *ngIf="config.multiple; else textInput"
          [placeholder]="config.placeholder | translate"
          [formControlName]="config.name"
          [required]="config.required"
          cdkTextareaAutosize
          [disabled]="shouldBeDisabled()"
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="4"></textarea>
          <ng-template #textInput>
            <input
              matInput
              type="text"
              [placeholder]="config.placeholder | translate"
              [formControlName]="config.name"
              [required]="config.required"
              [disabled]="shouldBeDisabled()"
              [readonly]="shouldBeDisabled()"
              ix-auto
              ix-auto-type="input"
              ix-auto-identifier="{{config.name}}"
            >
          </ng-template>
        </mat-form-field>
      </div>

    </div>
  </div>

  <div class="tree-container">
    <tree-root #tree id="form-ex-input" *ngIf="!shouldBeDisabled()" [nodes]="nodes" [options]="customTemplateStringOptions" [focused]="true" id="box3" (select)="onClick($event)" (deselect)="onClick($event)" (toggleExpanded)="onToggle($event)" (loadNodeChildren)="loadNodeChildren($event)">
      <ng-template #treeNodeTemplate let-node *ngIf="(config.explorerType == ExplorerType.Directory) || (config.explorerType == ExplorerType.Dataset) || (config.explorerType == ExplorerType.File) || !config.explorerType">
        <mat-icon *ngIf="node.data.hasChildren || node.data.children; else fileIcon">folder</mat-icon>
        <ng-template #fileIcon><mat-icon>insert_drive_file</mat-icon></ng-template>
        <span title="{{node.data.name}}">{{ node.data.subTitle }}
          <div
            class="dataset-subtitle"
            matTooltip="{{'An ACL is present in this path' | translate}}" matTooltipPosition="right"
            *ngIf="node.data['acl']"
          >ACL</div>
        </span>
      </ng-template>
    </tree-root>
  </div>
  <form-errors [control]="group.controls[config.name]" [config]="config"></form-errors>
  <mat-error *ngIf="config['hasErrors']"><div [innerHTML]="config['errors']"></div></mat-error>
  <mat-error *ngIf="config.warnings"><div [innerHTML]="config.warnings | translate"></div></mat-error>
</div>
