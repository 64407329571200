<div id="{{config.name}}" class="dynamic-field form-textarea paragraph" [formGroup]="group" [ngClass]="fieldShow" [style.width]="config.width ? config.width : 'auto'" [class.has-tooltip]="config.tooltip" *ngIf="!config['isHidden']">
  <div fxLayoutAlign="start start" fxLayoutGap="8px">
    <mat-icon class="paragraph-icon" *ngIf="config.paragraphIcon" [style.width]="config.paragraphIconSize" [style.height]="config.paragraphIconSize" [style.fontSize]="config.paragraphIconSize">{{ config.paragraphIcon }}</mat-icon>
    <p [class.large]="config.isLargeText" class="full-width" [innerHTML]="config.paraText | translate | docreplace"></p>
    <tooltip class="paratext-tooltip" *ngIf="config.tooltip" [header]="config.placeholder" [message]="config.tooltip"></tooltip>
  </div>
  <mat-error *ngIf="config['hasErrors']">{{config['errors']}}</mat-error>
  <mat-form-field class="full-width display-none">
    <input matInput [type]="config.inputType ? config.inputType : 'text'"
      [placeholder]="config.placeholder"
      [attr.value]="config.value"
      [formControlName]="config.name"
      >
  </mat-form-field>
</div>
