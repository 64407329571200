<div
  id="{{ config.name }}"
  class="form-element dynamic-field form-select {{ config.inlineLabel ? 'inline-label' : 'not' }}"
  [formGroup]="group"
  [ngClass]="fieldShow"
  [class.has-tooltip]="config.tooltip"
  [hidden]="config.isHidden"
>
  <div class="label-container" *ngIf="config.placeholder || config.tooltip">
    <ng-container *ngIf="config.inlineLabel">
      <div class="label half-width"></div>
    </ng-container>
    <label class="label input-select">
      {{ config.placeholder | translate }}
      <span *ngIf="config.required">*</span>
    </label>
    <tooltip
      *ngIf="config.tooltip"
      [header]="config.placeholder"
      [message]="config.tooltip"
    ></tooltip>
  </div>

  <ng-container *ngIf="config.inlineLabel">
    <div class="label half-width">{{ config.inlineLabel }}</div>
  </ng-container>
  <mat-form-field
    [ngClass]="{ 'full-width': !config.inlineLabel, 'half-width': config.inlineLabel }"
    value="fromFormField"
    #field
  >
    <mat-spinner
      diameter="40"
      class="form-select-spinner"
      id="{{ config.name }}_form-select-spinner"
      *ngIf="config.isLoading"
    >
    </mat-spinner>
    <mat-select
      #selectTrigger
      [value]="formValue"
      [formControlName]="config.name"
      [multiple]="config.multiple"
      [required]="config.required"
      (selectionChange)="onChangeOption($event)"
      ix-auto
      ix-auto-type="select"
      ix-auto-identifier="{{ config.placeholder }}"
    >
      <mat-select-trigger *ngIf="config.multiple">
        <span *ngFor="let value of customTriggerValue; last as isLast"> {{ value }}{{ isLast ? '' : ', ' }} </span>
      </mat-select-trigger>

      <ng-container *ngIf="formReady">
        <ng-container *ngFor="let option of config.options; let i = index">
          <!-- if disabled -->
          <mat-option
            *ngIf="option.disable && !option.hiddenFromDisplay"
            [value]="option.value"
            disabled
            ix-auto
            ix-auto-type="option"
            ix-auto-identifier="{{ config.placeholder }}_{{ option.label }}"
          >
            <span *ngIf="config.multiple">
              <mat-icon *ngIf="selectStates[i]" class="fn-checked">check_circle</mat-icon>
              <mat-icon *ngIf="!selectStates[i]" class="fn-unchecked">remove</mat-icon>
            </span>
            {{ option.label | translate }}
            <span *ngIf="shouldAlertOnOption(option)" class="alert-icon">
              <mat-icon role="img" fontSet="mdi-set" fontIcon="mdi-alert"></mat-icon>
            </span>
          </mat-option>

          <!-- else -->
          <mat-option
            *ngIf="!option?.disable"
            [value]="option.value"
            (click)="onToggleSelect(option)"
            ix-auto
            ix-auto-type="option"
            ix-auto-identifier="{{ config.placeholder }}_{{ option.label }}"
            [class.text-wrap]="config.enableTextWrapForOptions"
          >
            <span *ngIf="config.multiple">
              <mat-icon *ngIf="selectStates[i]" class="fn-checked">check_circle</mat-icon>
              <mat-icon *ngIf="!selectStates[i]" class="fn-unchecked">remove</mat-icon>
            </span>
            {{ option.label | translate }}
            <span *ngIf="shouldAlertOnOption(option)" class="alert-icon">
              <mat-icon role="img" fontSet="mdi-set" fontIcon="mdi-alert"></mat-icon>
            </span>
          </mat-option>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!formReady || config.options.length == 0">
        <ng-container>
          <mat-option
            disabled
            ix-auto
            ix-auto-type="option"
            ix-auto-identifier="{{ config.placeholder }}_zeroState"
            [class.text-wrap]="config.enableTextWrapForOptions"
          >
            {{ config.zeroStateMessage ? config.zeroStateMessage : '--' }}
          </mat-option>
        </ng-container>
      </ng-container>
    </mat-select>
  </mat-form-field>
  <div class="margin-for-error">
    <form-errors [control]="group.controls[config.name]" [config]="config"></form-errors>
    <mat-error *ngIf="config['hasErrors']"><div [innerHTML]="config['errors']"></div></mat-error>
    <mat-error *ngIf="config.warnings"><div [innerHTML]="config.warnings | translate"></div></mat-error>
  </div>
</div>
