<div
  *ngIf="!config['isHidden']"
  id="{{config.name}}"
  class="entity-slider"
  [formGroup]="group"
  [ngClass]="fieldShow"
  ix-auto ix-auto-type="slider" ix-auto-identifier="{{config.placeholder}}"
>
  <label>{{ config.placeholder | translate }}</label>
  <mat-slider class="full-width" thumbLabel [min]="config.min" [max]="config.max" [formControlName]="config.name" (change)="updateValue($event)" MatSliderChange>
  </mat-slider>
  <label><h5><b>{{value}}</b></h5></label>
</div>
