/*
* MISC CUSTOM ICONS
* When importing SVG asset files here
* please prep the SVG file by removing
* any styling form the <style> tag or inline
* as it conflicts with our application CSS.
*/

export const customSvgIcons = {
  multipath: 'assets/customicons/multipath.svg',
  ha_disabled: 'assets/customicons/ha_disabled.svg',
  ha_enabled: 'assets/customicons/ha_enabled.svg',
  ix_full_logo: 'assets/customicons/ix_full_logo.svg',
  ix_logomark: 'assets/customicons/ix_logomark.svg',
  ha_reconnecting: 'assets/customicons/ha_reconnecting.svg',
  truenas_logomark_color: 'assets/customicons/truenas_enterprise_logomark_rgb.svg',
  truenas_logotype_color: 'assets/customicons/truenas_enterprise_logotype_rgb.svg',
  truenas_logomark: 'assets/customicons/truenas_enterprise_logomark.svg',
  truenas_logotype: 'assets/customicons/truenas_enterprise_logotype.svg',
  truenas_enterprise_logomark: 'assets/customicons/truenas_enterprise_logomark.svg',
  truenas_enterprise_logotype: 'assets/customicons/truenas_enterprise_logotype.svg',
  truenas_enterprise_logo_full: 'assets/customicons/truenas_enterprise_logo_full.svg',
  truenas_enterprise_text_only: 'assets/customicons/truenas_enterprise_logotype.svg',
  truenas_enterprise_logomark_color: 'assets/customicons/truenas_enterprise_logomark_rgb.svg',
  truenas_enterprise_logotype_color: 'assets/customicons/truenas_enterprise_logotype_rgb.svg',
  truenas_core_logomark_color: 'assets/customicons/truenas_core_logomark_rgb.svg',
  truenas_core_logotype_color: 'assets/customicons/truenas_core_logotype_rgb.svg',
  truenas_core_logomark: 'assets/customicons/truenas_core_logomark.svg',
  truenas_core_logotype: 'assets/customicons/truenas_core_logotype.svg',
  truenas_core_logo_full: 'assets/customicons/truenas_core_logo_full.svg',
  truenas_scale_logomark: 'assets/customicons/truenas_scale_logomark.svg',
  truenas_scale_logotype: 'assets/customicons/truenas_scale_logotype.svg',
  truenas_scale_logo_full: 'assets/customicons/truenas_scale_logo_full.svg',
  truenas_scale_logomark_color: 'assets/customicons/truenas_scale_logomark_rgb.svg',
  truenas_scale_logotype_color: 'assets/customicons/truenas_scale_logotype_rgb.svg',
  freenas_logomark: 'assets/customicons/logo.svg',
  freenas_certified: 'assets/customicons/logo_certified.svg',
  freenas_logotype: 'assets/customicons/logo-text.svg',
  freenas_logo_full: 'assets/customicons/logo-full.svg',
  truecommand_logo_blue: 'assets/images/truecommand/truecommand-logo-mark-full-color-rgb.svg',
  truecommand_logo_white: 'assets/images/truecommand/truecommand-logo-mark-white-rgb.svg',
  'anti-lock': 'assets/customicons/anti-lock.svg',
  'network-upload-download': 'assets/customicons/network-upload-download.svg',
  'network-upload-download-disabled': 'assets/customicons/network-upload-download-disabled.svg',
};
