<h1 mat-dialog-title>
  {{ title | translate }}
</h1>
<div mat-dialog-content>
  <mat-select
    name="name"
    [placeholder]="optionPlaceHolder | translate"
    [(ngModel)]="displaySelection"
    (selectionChange)="switchSelection()"
    ix-auto ix-auto-type="select" ix-auto-identifier="{{optionPlaceHolder}}"
  >
    <mat-option
      *ngFor="let option of options"
      [value]="option.value"
      ix-auto
      ix-auto-type="option"
      ix-auto-identifier="option.label"
    >
      {{ option.label | translate }}
    </mat-option>
  </mat-select>
</div>
<div mat-dialog-actions class="action-buttons">
  &nbsp;
  <span fxFlex></span>
  <button
    mat-button
    class="mat-button mat-accent"
    (click)="dialogRef.close(false)"
    ix-auto
    ix-auto-type="button"
    ix-auto-identifier="CLOSE"
  >{{"Close" | translate }}</button>
  <button
    mat-button
    class="mat-button mat-primary"
    (click)="dialogRef.close(true)"
    [disabled]="displaySelection === undefined"
    ix-auto
    ix-auto-type="button"
    ix-auto-identifier="OK"
  >{{"OK" | translate }}</button>
</div>
