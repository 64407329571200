<ul [class.wizard-ul]="!isRoot" *ngIf="value && isAutoSummary() && fieldConfigs.length > 0">
  <ng-container *ngFor="let fieldConfig of fieldConfigs;">
    <li *ngIf="isVisible(fieldConfig)">
      <div *ngIf="fieldConfig.type == 'dict'; else notDict">
        <ng-container *ngIf="value[fieldConfig.name]">
          <label>{{fieldConfig.placeholder | translate}}:</label>
          <wizard-summary
            [fieldConfigs]="asFormDictConfig(fieldConfig).subFields"
            [value]="value[fieldConfig.name]"
            [isRoot]="false"
          ></wizard-summary>
        </ng-container>
      </div>
      <ng-template #notDict>
        <div>{{fieldConfig.placeholder | translate}}: {{getValue(fieldConfig)}}</div>
      </ng-template>
    </li>
  </ng-container>
</ul>
<div *ngIf="!isAutoSummary()">
  <ul >
    <li *ngFor="let item of summary | keyvalue : originalOrder">
      <div *ngIf="item.value | keyvalue; else inobject">
        <label>{{item.key}}:</label>
        <ul class="wizard-ul">
          <li *ngFor="let subitem of item.value | keyvalue : originalOrder">
            <div *ngIf="subitem.value">{{subitem.key | translate}}: {{subitem.value}}</div>
          </li>
        </ul>
      </div>
      <ng-template #inobject>
        <div *ngIf="item.value">{{item.key | translate}}: {{item.value}}</div>
      </ng-template>
    </li>
  </ul>
</div>
