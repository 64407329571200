<div
  class="dynamic-field form-element"
  [formGroup]="group"
  *ngIf="!config['isHidden']">

  <button
    mat-button
    [class]="config.buttonClass ? config.buttonClass : 'form-button'"
    [color]="config.buttonColor ? config.buttonColor : 'default'" type="button"
    (click)="config.customEventMethod($event)"
    [disabled]="config.disabled"
    ix-auto ix-auto-type="button" ix-auto-identifier="{{config.customEventActionLabel}}">
    {{config.customEventActionLabel | translate}}
  </button>
</div>
