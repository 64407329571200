<h1 mat-dialog-title>{{ title | translate }}</h1>
<div mat-dialog-content id="confirm-msg">
  <div class="form-input form-element">
    <div class="message-content">
      <span [innerHTML]="message | translate"></span>
    </div>
    <br/>
    <div class="password-content">
      <div class="label-container">
        <label class="label input-text">
          {{placeholder | translate}} *
        </label>
        <tooltip [header]="placeholder" [message]="tooltip"></tooltip>
      </div>
      <input name="DummyPassword" type="password" class="display-none">
      <input [type]="inputType"
			[required]="true"
			autocomplete="new-password"
			ix-auto
			ix-auto-type="input"
			ix-auto-identifier="rootpw"
			id="rootpw"
			[(ngModel)]="password">
      <button mat-icon-button type="button" class="toggle_pw" (click)="togglePassword()"
  		ix-auto
  		ix-auto-type="button"
  		ix-auto-identifier="rootpw_toggle-pw">
        <i class="material-icons" matTooltip="Hide" *ngIf="showPassword">visibility</i>
        <i class="material-icons" matTooltip="Show" *ngIf="!showPassword">visibility_off</i>
      </button>
      <div class="margin-for-error">
        <mat-error *ngIf="errors"><div [innerHTML]="errors | translate"></div></mat-error>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <span fxFlex></span>
  <button
    *ngIf="!hideCancel"
    id="confirm-dialog__cancel-button"
    mat-button
    name="cancel_button"
    class="mat-button mat-accent"
    (click)="dialogRef.close(false)"
    ix-auto
    ix-auto-type="button"
    ix-auto-identifier="{{cancelMsg | uppercase}}"
  >{{cancelMsg | translate | uppercase}}</button>
  <button
    id="confirm-dialog__action-button"
    mat-button
    name="ok_button"
    class="mat-button mat-primary"
    [disabled]="isDisabled()"
    (click)="submit()"
    ix-auto
    ix-auto-type="button"
    ix-auto-identifier="{{buttonMsg | uppercase}}"
  >{{buttonMsg | translate | uppercase}}</button>
</div>
