<div class="disk-ui-wrapper">
  <div class="disk-ui-type">
    {{data.type ? data.type : 'HDD'}}
  </div>
  <div class="disk-ui-icon">
    <mat-icon class="disk-icon" role="img" fontSet="mdi-set" fontIcon="mdi-harddisk"></mat-icon>
  </div>
  <div class="disk-ui-name">
    {{data.name}}
  </div>
</div>
