<ng-container *ngIf="errors.length == 0">
  <div *ngIf="system && system.enclosures && system_manufacturer && system_manufacturer == 'ixsystems'" class="page-viewport" fxLayout="row wrap">

    <!-- Persistent Visualizer -->
    <div class="main-content" fxFlex="calc(100% - {{ (system.enclosures.length * 240).toString() }}px )">

      <div>
        <mat-spinner [diameter]="40" *ngIf="spinner" class="spinner"></mat-spinner>
      </div>

      <ng-container *ngIf="supportedHardware">
        <div *ngIf="!system.platform.includes('MINI'); else mini" class="enclosure-disks-wrapper">
          <enclosure-disks
            *ngIf="events && system && system.pools && selectedEnclosure"
            [controller-events]="events"
            [current-tab]="currentView"
            [system-profiler]="system"
            [selected-enclosure]="selectedEnclosure">
          </enclosure-disks>
        </div>

        <ng-template #mini>
          <div>
            <enclosure-disks-mini
              *ngIf="events && system && system.pools && selectedEnclosure"
              [controller-events]="events"
              [current-tab]="currentView"
              [system-profiler]="system"
              [selected-enclosure]="selectedEnclosure">
            </enclosure-disks-mini>
          </div>
        </ng-template>
      </ng-container>
    </div>

    <!-- Enclosure Selector -->
    <div fxFlex="240px" fxFlex.lt-lg="180px" #navigation  *ngIf="showEnclosureSelector && events && system && system.pools && system.enclosures && system.enclosures.length > 1 && supportedHardware" class="enclosure-selector">
      <ng-container *ngFor="let enclosure of system.profile; let i = index">
        <div *ngIf="enclosure.enclosureKey != system.rearIndex" (click)="selectEnclosure(i)" class="enclosure enclosure-{{i}} {{selectedEnclosure.enclosureKey == i ? 'active' : '' }}">
          <div *ngIf="system.enclosures[enclosure.enclosureKey].label == system.enclosures[enclosure.enclosureKey].name">{{enclosure.model}} ({{i}})</div>
          <div *ngIf="system.enclosures[enclosure.enclosureKey].label !== system.enclosures[enclosure.enclosureKey].name">{{system.enclosures[enclosure.enclosureKey].label}} ({{i}})</div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="errors.length > 0">
  <div class="error mat-card">
    <h3 class="error-header">
      <mat-icon role="img" fontSet="mdi-set" fontIcon="mdi-alert"></mat-icon>
      {{errors[0].name}}
    </h3>

    <div class="error-message">
      {{errors[0].message}}.
      <br>
      {{ 'Please visit our support page for help.' | translate }}
    </div>

    <div class="error-footer">
      <button mat-button color="primary" (click)="router.navigate(['/system/support'])">{{ 'CONTACT SUPPORT' | translate }}</button>
    </div>
  </div>
</ng-container>

