<div class="notifications-header" fxLayout="row" fxLayoutAlign="space-between center">
  <h3 class="m-0">{{ 'Alerts' | translate }}</h3>

  <div class="p-0 m-0" fxLayout="row" fxLayoutAlign="end start">
    <button name="Settings" mat-icon-button
      [matMenuTriggerFor]="alertSettingsMenu" ix-auto
      ix-auto-type="button" ix-auto-identifier="settings">
      <mat-icon>settings</mat-icon>
    </button>
    <mat-menu #alertSettingsMenu="matMenu">
      <button
        name="settings-alert"
        mat-menu-item
        ix-auto
        ix-auto-type="option"
        ix-auto-identifier="Change Password"
        (click)="navigateTo(['/system', 'alertsettings'])"
      >
        <span>{{ 'Alert Settings' | translate }}</span>
      </button>
      <button
        name="services-alert"
        mat-menu-item
        ix-auto
        ix-auto-type="option"
        ix-auto-identifier="Preferences"
        (click)="navigateTo(['/system', 'alertservice'])"
      >
        <span>{{ 'Alert Services' | translate }}</span>
      </button>
      <button
        name="services-email"
        mat-menu-item
        ix-auto
        ix-auto-type="option"
        ix-auto-identifier="Email"
        (click)="navigateTo(['/system', 'email'])"
      >
        <span>{{ 'Email' | translate }}</span>
      </button>
    </mat-menu>
    <button
      name="close-alerts-panel"
      mat-icon-button
      ix-auto
      ix-auto-type="button"
      ix-auto-identifier="settings"
      (click)="closeNotificationsPanel()"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>

<mat-list class="notification-list" role="list">
  <!-- Notification item -->
  <mat-list-item *ngIf="notifications.length === 0" class="notific-item m-0 p-0" role="listitem">
    <mat-icon class="notific-icon info-icon">info</mat-icon>
    <div class="mat-list-text">
      <h4 class="message">{{ 'There are no alerts.' | translate }}</h4>
    </div>
  </mat-list-item>

  <mat-list-item
    *ngFor="let notification of notifications; let last = last"
    class="notific-item unread m-0 p-0"
    role="listitem"
    [class.last]="last"
  >
    <mat-icon
      [color]="notification.color"
      class="notific-icon"
      [matTooltip]="notification.icon_tooltip"
    >{{notification.icon}}</mat-icon>
    <div class="mat-list-text">
      <h3 class="notific-title {{notification.color}}">{{notification.level}}</h3>
      <h4 class="message" [innerHTML]="notification.message"></h4>
      <small class="time">{{notification.time_locale | date: ngDateFormat }} ({{notification.timezone}})</small>
      <a (click)="onDismiss(notification,$event)" class="dismiss">{{ 'Dismiss' | translate }}</a>
    </div>
  </mat-list-item>

  <div class="mat-list-item button-row sticky" *ngIf="notifications.length">
    <button mat-button (click)="closeAll($event)">
      <small>{{ 'Dismiss All Alerts' | translate }}</small>
    </button>
  </div>

  <mat-list-item
    *ngFor="let notification of dismissedNotifications; let last = last"
    class="notific-item read"
    role="listitem"
    [class.last]="last"
  >
    <mat-icon class="notific-icon dismissed" [matTooltip]="'DISMISSED' | translate">check_circle</mat-icon>
    <div class="mat-list-text dismissed">
      <h4 class="message" [innerHTML]="notification.message"></h4>
      <small class="time">{{notification.time_locale | date: ngDateFormat }} ({{notification.timezone}})</small>
      <a (click)="onRestore(notification, $event)" class="dismiss">{{ 'Re-Open' | translate }}</a>
    </div>
  </mat-list-item>

  <div class="mat-list-item button-row" *ngIf="dismissedNotifications.length">
    <button mat-button (click)="reopenAll($event)">
      <small>{{ 'Re-Open All Alerts' | translate }}</small>
    </button>
  </div>
</mat-list>
