<div class={{id}} [ngClass]="id !=='slide-in-form' ? 'jw-modal' : ''" cdkScrollable>
  <div class="jw-modal-body">
    <div class="slidein-title-bar" fxLayout="row">
      <div fxFlex="90%" fxLayout="row" fxLayoutAlign="start center">
        <h3 class="formtitle">{{ title | translate }}</h3>
        <div *ngIf="conf && conf.titleBarControls" class="control-group" fxLayout="row" fxLayoutAlign="start center">
          <div *ngFor="let control of conf.titleBarControls" class="control-group-inner">
            <toolbar-button *ngIf="control.type == 'button'" [config]="control" [controller]="conf.controller" ></toolbar-button>
            <toolbar-slider *ngIf="control.type == 'slider'" [config]="control" [controller]="conf.controller | cast"></toolbar-slider>
            <toolbar-input *ngIf="control.type == 'input'" [config]="control" [controller]="conf.controller"></toolbar-input>
            <toolbar-menu *ngIf="control.type == 'menu'" [config]="control" [controller]="conf.controller"></toolbar-menu>
            <toolbar-checkbox *ngIf="control.type == 'checkbox'" [config]="control" [controller]="conf.controller"></toolbar-checkbox>
          </div>
        </div>
      </div>
      <mat-icon fxFlex="10%" id="close-icon" (click)="close()">cancel</mat-icon>
    </div>
    <ng-container *ngIf="!wizard; else wizardBlock">
      <ng-container *appLet="asFormConfig(conf) as formConf">
        <ng-container *ngIf="(formOpen && formConf && !formConf.formType) || (formOpen && formConf && formConf.formType == 'EntityFormComponent')">
          <entity-form *ngIf="formConf.fieldSets" [conf]="formConf" class="slidein-entity-form"></entity-form>
        </ng-container>
      </ng-container>
      <ng-container *appLet="asEmbeddedConfig(conf) as embeddedConf">
        <ng-container *ngIf="formOpen && embeddedConf && embeddedConf.formType && embeddedConf.formType == 'EntityFormEmbeddedComponent'">
          <entity-form-embedded
            *ngIf="embeddedConf.fieldSets && embeddedConf.target"
            [target]="embeddedConf.target"
            [data]="embeddedConf.values"
            [conf]="embeddedConf"
            class="slidein-entity-form"
          ></entity-form-embedded>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #wizardBlock>
      <entity-wizard [conf]="asWizardConfig(conf)" *ngIf="formOpen" class="slidein-entity-form"></entity-wizard>
    </ng-template>
  </div>
</div>
<div class="jw-modal-background {{id}}-background" (click)="close()"></div>
