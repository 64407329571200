<div #tooltip class="tooltip" >
  <popper-content class="fn-theme-tooltip" #popperTooltip>

    <div class="tooltip-header">
      <div class="title-wrapper">
        <div><h5 class="tooltip-title">{{"Help" | translate}}{{ header ? ': ' : ''}}<i>{{ header ? (header | translate) : ''}}</i></h5></div>
      </div>
      <div>
        <mat-icon
          class="close-icon"
          role="img"
          fontSet="mdi-set"
          fontIcon="mdi-close"
          ix-auto (click)="popperTooltip.hide()"
          ix-auto-type="tooltip-close"
          ix-auto-identifier="{{header}}"
      ></mat-icon>
      </div>
    </div>
    <div
      class="tooltip-message"
      [innerHTML]="message | translate | docreplace"
      ix-auto
      ix-auto-type="tooltip-message"
      ix-auto-identifier="{{header}}"
    ></div>

  </popper-content>

  <img><i class="material-icons tooltip-icon"
    [popper]="popperTooltip"
    [popperShowOnStart]="false"
    popperAppendTo="body"
    [popperTrigger]="'click' | cast"
    ix-auto
    ix-auto-type="tooltip-icon"
    ix-auto-identifier="{{header}}">help_outline</i>
</div>


