<div mat-dialog-content>
  <div class="logo-container" fxLayout="row" fxLayoutAlign="start center">
    <div class="logo">
      <img [src]="dialogConfig.appInfo.chart_metadata.icon" [src-fallback]="imagePlaceholder"/>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <strong class="chart-name">{{ dialogConfig.appInfo.name }}</strong>
      <span class="version"> {{ dialogConfig.appInfo.human_version }}</span>
    </div>
  </div>
  <div class="resource" fxLayout="row" fxLayoutAlign="start center" *ngIf="selectedVersion.item_update_available; else noItemUpdate">
    <span>{{ 'Version to be upgraded to' | translate }}:</span>
    <mat-form-field class="form-element ">
      <mat-select [(ngModel)]="selectedVersionKey"  (ngModelChange)="onVersionOptionChanged()"
          ix-auto ix-auto-type="select" ix-auto-identifier="status-select">
        <mat-option *ngFor="let option of versionOptions | keyvalue: originalOrder" [value]="option.key">
          {{ option.value.latest_human_version }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <ng-template #noItemUpdate>
    <div class="resource">
      <span>{{ 'Only image(s) will be updated' | translate }}</span>
    </div>
  </ng-template>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'Images ( to be updated )' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansion-content">
        <div *ngIf="hasUpdateImages();else no_image">
          <div *ngFor="let containerImage of selectedVersion.container_images_to_update | keyvalue: originalOrder; let i = index" class="detail-row" [ngClass]="{'row-dark': i%2==0}">
            <div fxLayout="row" fxLayoutAlign="start center">
              <span fxFlex="10" class="text-center">{{ i + 1 }}</span>
              <span>{{ containerImage.key }}</span>
            </div>
          </div>
        </div>
        <ng-template #no_image>
          <div fxLayout="row" fxLayoutAlign="start start" class="detail-row row-dark">
            {{ 'There are no images requiring upgrade' | translate }}
          </div>
        </ng-template>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="selectedVersion.item_update_available">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'Changelog' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansion-content">
        <div fxLayout="row" fxLayoutAlign="start start" class="detail-row row-dark">
          <div [innerHTML]="selectedVersion.changelog || ('No Changelog' | translate)">
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div class="button-bar" mat-dialog-actions align="end">
  <button mat-button class="mat-button mat-secondary" (click)="dialogRef.close(false)" ix-auto ix-auto-type="button" ix-auto-identifier="CLOSE">
    {{ 'Close' | translate }}
  </button>
  <button mat-button class="mat-button mat-secondary" (click)="dialogRef.close(selectedVersionKey)" ix-auto ix-auto-type="button" ix-auto-identifier="UPGRADE">
    {{ 'Upgrade' | translate }}
  </button>
</div>
