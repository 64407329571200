<div id="entity-form-embedded.component_html">
  <ng-template [ngTemplateOutlet]="templateTop" [ngTemplateOutletContext]="this"></ng-template>
  <p *ngIf="!hasConf">
    {{"Please" | translate}} <button id="click_here_setup_configuration_button" mat-button color="primary" class="btn btn-link" type="button" (click)="goConf()"
      ix-auto ix-auto-type="button" ix-auto-identifier="CLICK HERE">{{"click here" | translate}}</button> {{"to set up configuration first." | translate}}
  </p>
  <p *ngIf="success" type="success">{{"Successfully updated." | translate}}</p>
  <p *ngIf="error" type="danger"><span [innerHTML]="error"></span></p>

  <div>
    <form (ngSubmit)="onSubmit($event)" [formGroup]="formGroup" #entityForm="ngForm" class="form-wrap">
      <div *ngIf="fieldSets && fieldSets.length > 0" class="fieldset-container fieldset-display-{{ fieldSetDisplay }}">
        <ng-container *ngFor="let fieldSet of fieldSets; let i = index">
          <div [ngClass]="{'hidden': hiddenFieldSets.indexOf(fieldSet.name) !== -1}" class="fieldset {{fieldSet.class}}"
            fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="100%" fxFlex.gt-xs="calc({{fieldSets[i].width}} - 16px)">
            <h4 *ngIf="fieldSet.label" class="fieldset-label">{{fieldSet.name | translate}}</h4>
            <div *ngFor="let field of fieldSet.config; let ii = index" fxFlex="100%" fxFlex.gt-xs="calc({{field.width}} - 16px)"
              [ngClass]="field.class == 'inline' ? 'form-inline' : 'form-line'" id="{{fieldSet.name}}-{{ii}}">
              <div id="form_field_{{field.name}}" dynamicField [config]="field" [group]="formGroup" [fieldShow]="isShow(field.name) ? 'show' :'hide'">
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <mat-card-actions style.text-align="{{actionButtonsAlign}}">
        <button class="submit_button" class="btn btn-block btn-warning" type="submit" mat-button class="mat-primary" color="primary" [disabled]="entityForm.dirty === false || !entityForm.form.valid"
          ix-auto ix-auto-type="button" ix-auto-identifier="{{saveSubmitText | uppercase}}">{{saveSubmitText | translate}}</button>
        <form-status *ngIf="conf.multiStateSubmit" [statusIcon]="saveSubmitStatus"></form-status>
        <button *ngIf="conf.goBack" id="goback_button" class="btn btn-block btn-lg btn" type="button" (click)="goBack()" mat-button color="default"
          ix-auto ix-auto-type="button" ix-auto-identifier="CANCEL">{{"Cancel" | translate}}</button>
        <span *ngFor="let custBtn of conf.custActions">
          <button id="cust_button_{{custBtn.name}}" mat-button color="default" class="mat-basic" *ngIf="!conf.isCustActionVisible || conf.isCustActionVisible(custBtn.id)" type="submit" (click)="onSubmit($event,custBtn.eventName)"
            ix-auto ix-auto-type="button" ix-auto-identifier="{{custBtn.name | uppercase}}">{{custBtn.name | translate}}</button>
        </span>
        <button id="delete_button" mat-button *ngIf="conf.routeDelete" class="btn btn-block btn-danger" type="button"
          ix-auto ix-auto-type="button" ix-auto-identifier="DELETE">{{"Delete" | translate}}</button>
      </mat-card-actions>
    </form>
  </div>
</div>
