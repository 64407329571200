<!-- START OF CONTROLS SECTION -->
<div *ngIf="entity && conf" fxFlex class="entity-table-controls" fxLayout="row wrap" fxLayoutAlignGap="16px" fxLayoutAlign="end center">

  <!-- TODO: Replace with ix-input when its ready -->
  <form class="form-element search-field" id="row-filter">
    <mat-form-field floatPlaceholder="never">
      <span matPrefix class="search-icon-wrapper"><mat-icon>search</mat-icon></span>
      <input
        #filter
        matInput
        placeholder="{{'Filter' | translate}} {{entity.title | translate}}"
        ix-auto
        ix-auto-type="input"
        ix-auto-identifier="Filter {{entity.conf.title}}"
        autocomplete="off"
      >
      <span
        class="reset-icon-wrapper"
        [class.invisible]="!filterValue || filterValue.length == 0"
        matSuffix
      >
        <mat-icon (click)="resetFilter()" role="img" fontSet="mdi-set" fontIcon="mdi-close-circle"></mat-icon>
      </span>
    </mat-form-field>
  </form>

  <!-- START COLUMN FILTER -->
  <div *ngIf="entity.columnFilter && entity.allColumns && entity.allColumns.length > 0">
    <button
      mat-button
      [matMenuTriggerFor]="menu"
      color="default"
      class="menu-toggle"
      ix-auto
      ix-auto-type="button"
      ix-auto-identifier="{{conf.title}}_COLUMNS"
    >
      {{"Columns" | translate}} <mat-icon class="menu-caret">arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu" multiple overlapTrigger="false">

      <!-- SELECT ALL -->
      <div (click)="$event.stopPropagation()">
        <button mat-menu-item (click)="entity.checkAll()" id="check-all"
          ix-auto
          ix-auto-type="action"
          ix-auto-identifier="COLUMNS_SELECT">
          <span>
            <mat-icon *ngIf="entity.checkLength()">check_circle</mat-icon>
            <mat-icon *ngIf="!entity.checkLength()">remove</mat-icon>
          </span>
          <span *ngIf="!entity.checkLength()">{{"Select All" | translate}}</span>
          <span *ngIf="entity.checkLength()">{{"Unselect All" | translate}}</span>
        </button>
      </div>

      <!-- INDIVIDUAL COLUMNS-->
      <div (click)="$event.stopPropagation();">
        <button mat-menu-item *ngFor="let col of entity.allColumns" (click)="entity.toggle(col);" id="menu_option-{{col.name}}"
          ix-auto
          ix-auto-type="action"
          ix-auto-identifier="COLUMNS_{{col.name}}">
          <span>
            <mat-icon *ngIf="entity.isChecked(col)">check_circle</mat-icon>
            <mat-icon *ngIf="!entity.isChecked(col)">remove</mat-icon>
          </span>
          <span>{{col.name | translate}}</span>
        </button>
      </div>

      <div (click)="$event.stopPropagation()">
        <button mat-menu-item (click)="entity.resetColViewToDefaults()" id="reset_col_view"
          ix-auto
          ix-auto-type="action"
          ix-auto-identifier="COLUMNS_Reset to Defaults">
          <span>
            <mat-icon>undo</mat-icon>
          </span>
          <span>{{"Reset to Defaults" | translate}}</span>
        </button>
      </div>
    </mat-menu>
  </div>
  <!-- END COLUMN FILTER -->


  <!-- OLD TEMPLATE START -->
  <div class="entity-add-actions-wrapper" *ngIf="this.totalActions> 0">
    <button
      mat-button color="primary"
      [matMenuTriggerFor]="menu"
      class="menu-toggle"
      *ngIf="this.totalActions> 1; else elseBlock"
      matTooltip="{{ this.menuTriggerMessage | translate}}"
      ix-auto
      ix-auto-type="button"
      ix-auto-identifier="{{entity.conf.title}}_ACTIONS"
    >
      {{"Actions" | translate}} <mat-icon class="menu-caret ">arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu" overlapTrigger="false">
      <button
        mat-menu-item
        (click)="this.entity.doAdd()"
        *ngIf="this.entity.conf.routeAdd || this.entity.conf.doAdd"
        id="add_action_button"
        ix-auto
        ix-auto-type="button"
        ix-auto-identifier="{{entity.conf.title}}_ADD"
      >
        <span>{{"Add" | translate }}</span>
      </button>
      <button
        *ngFor="let action of actions"
        id="add_action_button_{{action?.label}}"
        mat-menu-item
        (click)="action.onClick()"
        ix-auto
        ix-auto-type="button"
        ix-auto-identifier="{{entity.conf.title}}_{{action.label}}"
      >
        <span>{{action.label | translate}}</span>
      </button>
    </mat-menu>

    <ng-template #elseBlock>
      <!--When there is no routeAdd -->
      <button
        *ngIf="this.entity.conf.routeAdd || this.entity.conf.doAdd"
        [disabled]="entity.conf.addBtnDisabled"
        mat-button color="primary"
        (click)="this.entity.doAdd()"
        matTooltip="{{this.entity.conf.routeAddTooltip | translate}}"
        id="add_action_button"
        ix-auto
        ix-auto-type="button"
        ix-auto-identifier="{{entity.conf.title}}_ADD"
      >{{"Add" | translate}}</button>
      <button
        *ngIf="!(this.entity.conf.routeAdd || this.entity.conf.doAdd) && this.totalActions== 1"
        id="add_action_button_{{actions[0]?.label}}"
        mat-button color="primary"
        (click)="this.actions[0].onClick()"
        matTooltip="{{actions[0].label | translate}}"
        ix-auto
        ix-auto-type="button"
        ix-auto-identifier="{{entity.conf.title}}_{{this.actions[0].label}}"
      >
        {{this.actions[0].label | translate }}
      </button>
    </ng-template>
  </div>
  <!-- OLD TEMPLATE END -->

  <ng-container *ngIf="conf && conf.custActions">
    <span *ngFor="let custBtn of conf.custActions">
      <button
        *ngIf="!conf.isCustActionVisible || conf.isCustActionVisible(custBtn.id)"
        id="cust_button_{{custBtn.name}}"
        mat-button
        ix-auto ix-auto-type="button" ix-auto-identifier="{{custBtn.name}}"
        type="button"
        color="primary"
        (click)="custBtn['function']()"
      >
        {{custBtn.name | translate}}
      </button>
    </span>
  </ng-container>

  <div id="config" *ngIf="conf.globalConfig">
    <button
      mat-icon-button
      id="{{ conf.globalConfig.id }}"
      [matTooltip]="conf.globalConfig.tooltip"
      (click)="conf.globalConfig.onClick()"
      ix-auto ix-auto-type="settings" ix-auto-identifier="{{entity.title}}"
    >
      <mat-icon *ngIf="conf.globalConfig.icon; else settingsIcon">{{conf.globalConfig.icon}}</mat-icon>
      <ng-template #settingsIcon><mat-icon>settings</mat-icon></ng-template>
    </button>
  </div>

  <mat-spinner
    [diameter]="40"
    id="entity-spinner"
    *ngIf="!entity.showDefaults && entity.showSpinner"
    #entityspinner
    class="spinner"
  >
  </mat-spinner>

  <!-- END OF CONTROLS SECTION -->

</div>
